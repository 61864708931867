import React, { useState } from 'react';
import { Navbar, Nav, Form, FormControl, Button, Image } from 'react-bootstrap';
import '../resources/css/AppNavbar.css'
import { Redirect, useHistory } from 'react-router-dom';

function AppNavbar() {
  const [query, setQuery] = useState(null);
  const history = useHistory();

  const handleSearch = (event) => {
    if (!query) {
      return;
    }
    history.push(`/search?q=${query}`)
    event.preventDefault();
  };

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  return (
    <Navbar bg="dark" expand="sm" sticky="top">
      <Navbar.Brand href="/">
        <Image src={require('../resources/images/hoogle_icon.png')} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Form inline onSubmit={handleSearch}>
            <FormControl type="text" placeholder="Search for audio..." className="mr-sm-2" size="lg" onChange={handleChange} />
            <Button onClick={handleSearch} variant="outline-success" size="lg">Search</Button>
          </Form>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default AppNavbar;
