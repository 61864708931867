import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import './resources/css/index.css'

// Import our components
import App from './components/App';
import AppNavbar from './components/AppNavbar'
import Search from './components/Search';
import Lecture from './components/Lecture';

// Only import bootstrap once
import 'bootstrap/dist/css/bootstrap.min.css';


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AppNavbar/>
      <Switch>
        <Route exact path="/" component={App} />
        <Route path="/search">
          <Search/>
        </Route>
        <Route path="/lecture">
          <Lecture/>
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

