import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, FormControl, Button, Image, Row, Col } from 'react-bootstrap';
import '../resources/css/App.css';

function App() {
  const [query, setQuery] = useState(null);
  const history = useHistory();

  const handleSearch = (event) => {
    if (!query) {
      return;
    }
    history.push(`/search?q=${query}`)
    event.preventDefault();
  };

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  return (
    <div className="grid-container">
      <div className="logo">
        <Image className="img" src={require('../resources/images/hoogle_logo.png')} />
      </div>
      <Form className="search" onSubmit={handleSearch}>
        <FormControl type="text" placeholder="Search for audio..." className="mr-sm-2" size="lg" onChange={handleChange} />
        <Button onClick={handleSearch} variant="primary" size="lg">Search</Button>
      </Form>
      <div className="description">
        <h2>Find audio in a lecture just by typing!</h2>
      </div>
      <div className="footer">
        <p>Built by Brian Yu, Jackson Kennedy, and Brad Knaysi</p>
      </div>
    </div>
  );
}

export default App;
