import React, { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import ReactAudioPlayer from 'react-audio-player';
import { useLocation } from "react-router-dom";

import '../resources/css/Lecture.css';

// Constants
const storage_url = "https://storage.cloud.google.com/hoogle_audio_bucket-1/"

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Lecture() {
    const refAudioPlayer = useRef();

    const queryParser = useQuery();

    const [filename, setFilename] = useState(null);
    const [timestamp, setTimestamp] = useState(null);

    // Methods using audio player ref
    const jumpToStartedFrom = () => {
        refAudioPlayer.current.audioEl.current.currentTime = timestamp;
    }

    // Get query from URL params.
    useEffect(() => {
        setFilename(queryParser.get('filename'));
        setTimestamp(queryParser.get('timestamp'));
    }, [filename, timestamp, queryParser]);

    
    return (
        <div className="container">
            <div className="center-screen">
                <div>
                    <h3>Listening to: </h3> <p>{filename}</p>
                    <h3>Started from: </h3><p>{parseInt(timestamp / 60)} min {parseInt(timestamp % 60)} sec</p>
                </div>
                <div className="audio-box">
                    <ReactAudioPlayer
                        className="mb-2 mt-4"
                        src={storage_url + filename}
                        ref={refAudioPlayer}
                        onLoadedMetadata={jumpToStartedFrom}
                        autoPlay
                        controls
                    />
                    <Button variant="secondary" className="mt-2" onClick={jumpToStartedFrom}>
                        Jump to Started from
                    </Button> 
                </div>
            </div>
        </div>
    )
}

export default Lecture;