import React, { useEffect, useState } from 'react';
import { useLocation, Link } from "react-router-dom";
import '../resources/css/Search.css';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Result = (props) => {
    const result = props.result;
    const filename = result.filename.replace(".json", ".wav");
    const lectureLink = `lecture?filename=${filename}&timestamp=${result.time}`

    return (
        <li>
            <Link to={lectureLink}>
                "<span className="highlight" dangerouslySetInnerHTML={{"__html": result.highlight}}></span>"
            </Link>
            <p className="ml-3 small">{filename}&nbsp;&mdash;&nbsp;{result.time} sec</p>
        </li>
    );
}

const Results = (props) => {
    const results = props.results.results;
    const query = props.results.query;
    console.log(results)

    const resultList = results.map((result, idx) => <Result result={result} key={idx}/>)
    return (
        <div>
            <h3>Showing {results.length} results for "{query}"</h3>
            <div className="results">
                <ul>
                    {resultList}
                </ul>
            </div>
        </div>
    )
}

function Search() {
    const queryParser = useQuery();

    const [query, setQuery] = useState(null);
    const [results, setResults] = useState(null);

    // Get query from URL params.
    useEffect(() => {
        setQuery(queryParser.get('q'));
    }, [query, queryParser]);

    // Get search results.
    useEffect(() => {
        if (query === null) {
            return;
        }

        const searchURL = process.env.NODE_ENV === 'production' ? 'https://cloud.byu.io/search' : '/search';

        fetch(`${searchURL}?q=${query}`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setResults(data);
            });
    }, [query]);

    return (
        <div className="container-fluid">
            {
                results ? 
                <Results results={results} /> :
                <h3>Loading...</h3>
            }
        </div>
    )
}

export default Search;